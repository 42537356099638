<!-- 政策订阅-->
<template>
  <div>
    <div class="def-container" style="margin-top:2rem">
      <el-row>
        <el-col :span="12" style="font-size: 32px; color: #0a44a5">
          政策订阅
        </el-col>
      </el-row>
    </div>
    <div class="def-container find-list-container">
      <div class="findList" ref="findList">
        <!--筛选-->
        <div class="find-tabs">
          <FilterGroup @change="changeSearch">
            <FilterCheckBox
              v-model="searchInfo.data.hyle"
              label="行业类别"
              :list="getDictList('sys_category')"
              valueKey="key"
              :needAll="false"
              fold
            ></FilterCheckBox>
            <FilterCheckBox
              v-model="searchInfo.data.zcfl"
              label="政策分类"
              :list="getDictList('sq_policy_xmlb')"
              valueKey="key"
              :needAll="false"
              fold
            ></FilterCheckBox>
            <FilterRadio
              v-model="searchInfo.data.dq"
              label="地区"
              :list="getDictList('sq_policy_xmlb')"
              valueKey="key"
              :needAll="false"
              fold
            ></FilterRadio>
            <FilterRadio
              v-model="searchInfo.data.deptcode"
              label="发布部门"
              :list="searchInfo.deptList"
              valueKey="deptid"
              labelKey="deptname"
              :needAll="false"
              fold
            ></FilterRadio>
            <FilterItem label="已选择" :needAll="false">
              <div class="filter-params-inner">
                <div class="filterItem-params-left">
                  <span v-if="!cpu_paramsList.length">无</span>
                  <a
                    v-else
                    class="pad-rl-1 search-params-clean"
                    @click="cleanParams"
                    >清空</a
                  >
                  <el-tag
                    v-for="(item, index) in cpu_paramsList"
                    :key="index"
                    closable
                    @close="closeParams(item)"
                  >
                    {{ item.label }}
                  </el-tag>
                </div>
              </div>
            </FilterItem>
          </FilterGroup>
        </div>
      </div>
      <div style="text-align: center;margin-top:2rem">
        <el-button style="color: #fff; background-color: #0d55cf;width:22.5rem"
          >订阅</el-button
        >
        <p style="margin-top:2rem">订阅政策可在“个人中心/推送消息/<a>政策推送</a>查看</p>
      </div>
      <!--登录提示弹窗-->
      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <span>需要登录才能收藏，是否登录？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="gotoLogin">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  FilterGroup,
  FilterRadio,
  FilterCheckBox,
  FilterItem,
} from "@/views/policyLibrary/components/filter/index.js";

export default {
  components: {
    FilterGroup,
    FilterRadio,
    FilterCheckBox,
    FilterItem,
  },
  data() {
    return {
      dialogVisible: false, //登录提示弹窗
      showList: [],
      activeTab: "0", // 3大类 1 审批 2 资金 3 诉求
      collectList: [],
      // 搜索条件
      searchInfo: {
        data: {
          xmlb: "", // 项目类别
          cylb: "", // 产业类别
          deptcode: "", // 发布部门
          zcfs: "", // 支持方式
          temp_amount: "", // 资金限额
          zzxeleft: "", // 起始资金限额
          zzxeright: "", // 结束资金限额
          name: "", // 事项名称
          hyle: "", //行业类别
          zcfl: "", //政策分类
        },
        oldParams: {},
        deptList: [], // 职能部门列表
        amountList: [
          { value: "0", label: "1000万及以上" },
          { value: "1", label: "500万~1000万" },
          { value: "2", label: "100万~500万" },
          { value: "3", label: "100万及以下" },
          // { value: '4', label: '自定义' },
        ],
        type: "0", // 搜索类型{'0':传统列表, '1':数字化列表}
      },
      dataLoading: false, // dataLoading状态位
      // 分页
      pageInfo: {
        page: 1,
        size: 5,
        total: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
      getDictList: "dictionary/getDictList",
      getDictLabel: "dictionary/getDictLabel",
    }),
    /**
     * @description: 产业类别列表
     */
    cpu_cylbList() {
      let list = JSON.parse(JSON.stringify(this.getDictList("sys_enter_type")));
      if (!!list.length) {
        list[0].key = "";
        list[0].label = "不限";
      }

      return list;
    },
    /**
     * @description: 已选择的搜索条件列表
     * @return {Array}
     */
    cpu_paramsList() {
      let list = [];
      const params = this.searchInfo.oldParams;
      // console.log('oldParams:', params)
      if (params.deptcode) {
        list.push({
          type: "deptcode",
          value: params.deptcode,
          label: this.searchInfo.deptList.find(
            (cv) => cv.deptid === params.deptcode
          ).deptname,
        });
      }
      if (params.xmlb) {
        list.push({
          type: "xmlb",
          value: params.xmlb,
          label: this.getDictList("sq_policy_xmlb").find(
            (cv) => cv.key === params.xmlb
          ).label,
        });
      }
      if (params.cylb) {
        list.push({
          type: "cylb",
          value: params.cylb,
          label: this.cpu_cylbList.find((cv) => cv.key === params.cylb).label,
        });
      }
      if (params.zcfs) {
        list.push({
          type: "zcfs",
          value: params.zcfs,
          label: this.getDictList("sq_policy_zjlx").find(
            (cv) => cv.key === params.zcfs
          )?.label,
        });
      }
      if (params.temp_amount) {
        let item = {
          type: "temp_amount",
          value: params.temp_amount,
          label: "",
        };
        switch (params.temp_amount) {
          case "0":
          case "1":
          case "2":
          case "3":
            item.label = this.searchInfo.amountList.find(
              (cv) => cv.value == params.temp_amount
            ).label;
            break;
          default:
            item.label = `${params.zzxeleft || "--"}万元 ~ ${
              params.zzxeright || "--"
            }万元`;
            break;
        }
        list.push(item);
      }
      // 行业类别
      if (params.hyle) {
        list.push({
          type: "zcfs",
          value: params.hyle,
          label: this.getDictList("sys_category").find(
            (cv) => cv.key === params.hyle
          )?.label,
        });
      }
      // 政策分类
      if (params.zcfl) {
        list.push({
          type: "zcfl",
          value: params.zcfl,
          label: this.getDictList("sq_policy_xmlb").find(
            (cv) => cv.key === params.zcfl
          )?.label,
        });
      }
      return list;
    },
    /**
     * @description: 表格 办理对象 显示文本
     * @param {String} bldx 办理对象
     * @return {String}
     */
    cpu_blzcText() {
      return function (bldx) {
        if (!bldx) return "";

        return this.getDictLabel("permit_sertype", bldx);
      };
    },
    /**
     * @description: 表格 企业注册类型 显示文本
     * @param {String} qyzclx 企业注册类型
     * @return {String}
     */
    cpu_qyzclxText() {
      return function (qyzclx) {
        if (!qyzclx) return "";

        let temp_qyzclx = qyzclx
          .split(",")
          .map((cv) => this.getDictLabel("sys_enterprise_registertype", cv))
          .join(",");
        return temp_qyzclx;
      };
    },
    cpu_hylbText() {
      return function (hylb) {
        if (!hylb) return "--";

        return hylb
          .split(",")
          .map((cv) => this.getDictLabel("sys_category", cv))
          .join(", ");
      };
    },
    /**
     * @description: 荣誉信息/资质认证要求 的显示文本
     * @param {String} ryxx 荣誉信息
     * @param {String} zzrzyq 资质认证要求
     * @return {String}
     */
    cpu_ryzzText() {
      return function (ryxx, zzrzyq) {
        if (ryxx && zzrzyq) {
          return `${ryxx} / ${zzrzyq}`;
        } else if (!ryxx && !zzrzyq) {
          return "";
        }
        return ryxx || zzrzyq;
      };
    },
    cpu_noText() {
      return function (val) {
        return val || "--";
      };
    },
  },
  methods: {
    /**
     * @description 清空已选搜索项
     */
    cleanParams() {
      for (const k of Object.keys(this.searchInfo.data)) {
        this.searchInfo.data[k] = "";
      }
      this.searchInfo.oldParams = JSON.parse(
        JSON.stringify(this.searchInfo.data)
      );

      this.showList = [];
      this.pageInfo.total = 0;
      this.getListData(1, this.pageInfo.size);
    },
    /**
     * @description: 删除已选搜索项
     * @param {Object} tag
     */
    closeParams(tag) {
      switch (tag.type) {
        case "temp_amount":
          this.searchInfo.data.temp_amount = "";
          this.searchInfo.data.zzxeleft = "";
          this.searchInfo.data.zzxeright = "";
          break;
        default:
          this.searchInfo.data[tag.type] = "";
          break;
      }
      this.searchInfo.oldParams = JSON.parse(
        JSON.stringify(this.searchInfo.data)
      );

      this.showList = [];
      this.pageInfo.total = 0;
      this.getListData(1, this.pageInfo.size);
    },
    /**
     * @description: 修改搜索类型
     * @param {String} val 值
     */
    changeSearchType(val) {
      this.searchInfo.oldParams = JSON.parse(
        JSON.stringify(this.searchInfo.data)
      );

      this.showList = [];
      this.pageInfo.total = 0;
      this.getListData(1, this.pageInfo.size);
    },
    /**
     * @description 合并表格行列
     * @param {Object} row 行
     * @param {Object} column 列
     * @param {Number} rowIndex 行下标
     * @param {Number} columnIndex 行下标
     */
    mergeTable({ row, column, rowIndex, columnIndex }) {
      // console.log('mergeTable:', row, column, rowIndex, columnIndex)
      if (column.type === "index" || column.property === "name") {
        // 是否与上一行同政策，是 则不占单元格
        if (0 < rowIndex && row.name === this.showList[rowIndex - 1].name) {
          return {
            rowspan: 1,
            colspan: 0,
          };
        }
        // 是否与下一行同政策，是 则占2行单元格
        let rowNum = 1;
        if (rowIndex < this.showList.length - 1) {
          for (let i = rowIndex + 1; i < this.showList.length; i++, rowNum++) {
            if (row.name !== this.showList[i].name) break;
          }
          return {
            rowspan: rowNum,
            colspan: 1,
          };
        }
      }
    },
    /**
     * @description: 点击前往政策办事指南
     * @param {Object} row 政策信息
     */
    toPolicyGuide(row, column) {
      // console.log('toPolicyGuide:', row, column)
      // 如果点击的是 政策项目 一列，办事指南中的 资助标准 显示所有项
      // 否，则传入qxId，办事指南中的资助标准显示对应的一项
      this.$store.commit("updateCurrentGuideId", row.id);
      let query = {
        id: row.id,
        name: row.name,
      };
      column.property !== "name" && (query.qxId = row.qxid);
      const { href } = this.$router.resolve({
        path: "/guideSuqiu",
        query: query,
      });
      window.open(href, "_blank");
    },
    //查询所有的收藏列表,之后需要比对事项的收藏状态
    getCollectList() {
      if (this.userInfo) {
        let identify = this.userInfo.tyshxydm;
        let param = {
          identify: identify,
          type: "1,2,3,4,5,6",
          pageNum: 1,
          pageSize: 100,
        };
        let url = "/dev-api/collection/list";

        this.$httpApi
          .get(url, param)
          .then((res) => {
            // console.log('getCollectList:', res)
            if (res.code == 200) {
              this.collectList = res.rows;
            }
          })
          .catch((err) => {});
      }
    },
    //比对收藏列表,判断是否收藏
    isItemCollect(item) {
      let list = this.collectList;

      for (let i = 0; i < list.length; i++) {
        let collectItem = list[i];
        if (item.id == collectItem.collectionId) {
          // console.log(item.name + ":已收藏");
          return collectItem;
        }
      }
      return null;
    },
    /**
     * @description: 切换页码
     * @param {Number} page 页码
     */
    handleCurrentChange(page) {
      this.showList = [];

      this.getListData(page, this.pageInfo.size);
    },
    /**
     * 监听declareCeil子组件点击收藏事件
     */
    clickIsFollow(item) {
      //判断是否登录
      if (this.$store.getters.getUserInfo) {
        //登录
      } else {
        //未登录
        this.dialogVisible = true;
      }
    },
    /*
     * 监听DeclareCeil子组件点击获取详情事件
     */
    clickCell(item) {
      // console.log("选择的是:", item);
      // alert("转跳")
      this.$store.commit("updateCurrentGuideId", item.id);
      if (this.activeTab == 0) {
        this.$router.push({
          path: "/guideSuqiu",
          query: {
            name: item.name,
            itemType: item.itemtype,
            isAdded: item.isAdded,
          },
        });
      } else {
        this.$router.push({
          path: "/guideSuqiu",
        });
      }
    },
    /**
     * 登录提示框点击确定登录，跳转登录页面
     */
    gotoLogin() {
      console.log("跳转登录页面");
      this.$router.push({
        path: "/login",
      });
    },
    /**
     * 根据专项名称关键字搜索
     */
    searchByKeyword(data) {
      this.searchInfo.data.name = data;
      this.searchInfo.oldParams = JSON.parse(
        JSON.stringify(this.searchInfo.data)
      );

      this.showList = [];
      this.pageInfo.total = 0;
      this.getListData(1, this.pageInfo.size);
    },
    /**
     * @description: 修改搜索条件
     */
    changeSearch(label, value) {
      // 特殊情况不需要触发搜索
      //     资金限额自定义时
      switch (label) {
        case "支持方式":
          if (value !== "1") {
            this.$set(this.searchInfo.data, "temp_amount", "");
            this.searchInfo.data.zzxeleft = "";
            this.searchInfo.data.zzxeright = "";
          }
          break;
        case "资金限额":
          if (value == "4") return;
          this.searchInfo.data.zzxeleft = "";
          this.searchInfo.data.zzxeright = "";
          break;
        default:
          break;
      }
      this.searchInfo.oldParams = JSON.parse(
        JSON.stringify(this.searchInfo.data)
      );

      this.showList = [];
      this.pageInfo.total = 0;
      this.getListData(1, this.pageInfo.size);
    },
    /**
     * @description 查询职能部门列表
     */
    getDeptList() {
      let url = "/dev-api/sqzdManager/data/find/sq_policy_project_dept_list";
      let params = {};
      this.$httpApi
        .post(url, params)
        .then((res) => {
          // console.log('getDeptList:', res)
          if (res.data && !!res.data.length) {
            this.searchInfo.deptList = res.data || [];
          }
        })
        .catch((err) => {
          console.log("getDeptList error:", res);
        });
    },
    /**
     * @description: 查询政策列表
     */
    getListData(page, size) {
      /**
       * 20210520
       * 查询区分了 传统列表/数字化列表
       * 为了可控，用switch分开处理逻辑
       *
       * 20210615
       * 资助标准列表，如果按资助标准的维度来查询，会带来序号的问题
       * 逻辑改为，先调传统政策项目列表，再传批量id查询资助标准
       */
      this.pageInfo.page = page ?? this.pageInfo.page;
      this.pageInfo.size = size ?? this.pageInfo.size;

      let url =
        "/dev-api/cfgpermitconfig/query/data/find/cfg_permitinfo_andzchx_list";
      let params = {
        itemtype: "2",
        release: "1",
        ...this.searchInfo.oldParams,
        ["PAGE_CUR"]: this.pageInfo.page.toString(),
        ["PAGE_ROWS"]: this.pageInfo.size.toString(),
      };
      // 特殊处理一下 奖励金额
      switch (params.temp_amount) {
        case "0":
          params.zzxeleft = 1000;
          break;
        case "1":
          params["zzxe_500_1000"] = "1";
          break;
        case "2":
          params["zzxe_100_500"] = "1";
          break;
        case "3":
          params.zzxeright = 100;
          break;
        case "4":
          params.zzxeleft = Number(params.zzxeleft);
          params.zzxeright = Number(params.zzxeright);
          break;
        default:
          break;
      }
      Reflect.deleteProperty(params, "temp_amount");

      this.dataLoading = true;
      this.$httpApi
        .post(url, params)
        .then((res) => {
          console.log("getListData:", res);
          this.dataLoading = false;

          this.pageInfo.total = res.pageInfo.pageCount || 0;
          switch (this.searchInfo.type) {
            case "0":
              this.showList = res.data || [];
              break;
            case "1":
              if (res.data && !!res.data.length) {
                let zzbzUrl =
                  "/dev-api/sqzdManager/data/find/sq_policy_project_zzqx_list";
                let zzbzParams = JSON.parse(JSON.stringify(params));
                zzbzParams.sxids = res.data.map((cv) => cv.id).join(",");
                Reflect.deleteProperty(zzbzParams, "PAGE_CUR");
                Reflect.deleteProperty(zzbzParams, "PAGE_ROWS");

                this.$httpApi.post(zzbzUrl, zzbzParams).then((resp) => {
                  console.log("zzbzData:", resp);
                  this.showList = [];
                  // 处理下序号的问题
                  // 按照政策项目，依次推入
                  for (let i = 0; i < res.data.length; i++) {
                    let temp_zzbzList = resp.data.filter(
                      (cv) => cv.id === res.data[i].id
                    );
                    temp_zzbzList.forEach((zzbz) => {
                      zzbz.serialNumber = i;
                      this.showList.push(zzbz);
                    });
                  }

                  // 获取 企业资质
                  for (let i = 0; i < this.showList.length; i++) {
                    let qyzzUrl =
                      "/dev-api/sqzdManager/data/find/sq_policylabelname_query";
                    let qyzzParams = { labelcodes: this.showList[i].qyzz };
                    this.$httpApi
                      .post(qyzzUrl, qyzzParams)
                      .then((respo) => {
                        // console.log('getQyzz:', respo)
                        if (respo.data && !!respo.data.length) {
                          this.$set(
                            this.showList[i],
                            "qyzz_text",
                            respo.data.map((cv) => cv.name).join(", ")
                          );
                        }
                      })
                      .catch((err) => {
                        console.log("getQyzz error:", err);
                      });
                  }
                });
              }
              break;
            default:
              break;
          }
        })
        .catch((err) => {
          console.log("getListData error:", err);
          this.dataLoading = false;
        });
    },
  },
  created() {
    this.$store.dispatch("dictionary/commonSetDic", {
      types: [
        "sq_policy_xmlb",
        "sys_enter_type",
        "sq_policy_zjlx",
        "permit_sertype", // 办理对象
        "sys_enterprise_registertype", // 注册类型
        "sys_category", // 行业类别(国民经济分类)
      ],
    });
    this.getDeptList();
  },
  mounted() {
    this.getListData();
  },
  watch: {
    userInfo: {
      // deep: true,
      immediate: true,
      handler: function (newVal) {
        // console.log('userInfo:', newVal)
        this.getCollectList();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/com/theme.scss";

.find-list-container {
  .banner-size {
    background: url("../../assets/images/icon_banner_declare.png");
  }

  .banner-info {
    height: 100%;
    display: flex;
    align-items: center;

    .banner-other {
      margin-left: 15px;
    }
  }

  .section-header {
    border-bottom: 4px solid #000;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }

  .section-title {
    font-size: 24px;
    color: rgb(13, 28, 40);
    font-weight: 600;
    height: 46px;
    line-height: 46px;
  }

  .rank-section {
    padding-top: 10px;
    font-size: 13px;
  }

  .find-tabs {
    margin-top: 20px;
  }
}
.find-list-container {
  .el-input {
    input {
      background-color: #ffffff;
      border-radius: 0px !important;
    }
  }

  .el-input-group__append {
    button {
      background-color: rgb(75, 145, 233);
    }
  }

  .el-row {
    width: 360px;
    display: flex;

    .el-button {
      border: 0px;
      border-radius: 0px;
      width: 90px;

      .el-icon-bottom {
        margin-left: 15px;
      }

      &:hover {
        background-color: #2d8cf0;
      }
    }
  }
}
::v-deep .searchItem-amount {
  border-top: none !important;

  .filterItem-label {
    opacity: 0;
  }
  .filterItem-content {
    padding-left: 73px;

    .content-item--active {
      color: $col-theme !important;
      background: none !important;
    }
  }

  .searchItem-amount-custom {
    display: -webkit-flex;
    display: flex;
    align-items: center;
    color: #999999;

    .form-input {
      width: 80px;
      margin-right: 5px;
    }
  }
}
.search-params-clean {
  margin-right: 5px;
  color: $col-red;
}

.find-list-items-container {
  .item {
    margin-bottom: 10px;
    box-sizing: border-box;
  }
}
::v-deep .table-policy {
  .table-policy-header {
    th {
      color: #666666;
      background-color: #dbdada;
    }
  }
  .policy-item {
    .policyItem-hylb,
    .policyItem-qyzz,
    .policyItem-ryxxyq {
      display: -webkit-box;
      display: -moz-box;
      -webkit-box-orient: vertical;
      -moz-box-orient: vertical;
      -webkit-line-clamp: 3;
      line-clamp: 3;
    }
    .policyItem-zzxe {
      color: $col-red;
    }
    .policyItem-gssz {
      display: -webkit-flex;
      display: flex;
      align-items: center;

      p {
        flex: 1;
        padding-right: 20px;
      }
      .tj-icon {
        font-size: 20px;
        color: #999999;

        &:hover {
          color: #666666;
        }
      }
    }

    &:hover {
      font-weight: normal;
      cursor: pointer;
    }
  }
}
.pagination {
  margin: 30px auto;
  text-align: center;
}
.filter-params-inner {
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ::v-deep .el-tag {
    margin-right: 5px;
    color: $col-theme;

    .el-tag__close {
      color: $col-theme;

      &:hover {
        color: #ffffff;
      }
    }
  }
}

</style>
